@import "~public/theme/scss/bootstrap/variables";
@import "~public/theme/scss/bootstrap/mixins";

$desktop-screen-width: 1920px;

.brandLiveChatStickyButton {
  transition: $transition-base;

  @include media-breakpoint-up(sm) {
    width: ($desktop-screen-width - map-get($container-max-widths, xl)) / 2;
  }

  &[data-status="loaded"] {
    opacity: 0;
    transform: translateY(100%);
  }
}
