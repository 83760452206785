@import "~shared/theme/scss/bootstrap/variables";
@import "~shared/theme/scss/bootstrap/mixins";

.itemSlider-arrow {
  &, &:hover, &:active {
    border-color: transparent;
    box-shadow: 0 $btn-border-width $btn-border-width $btn-border-width $gray-200-rgba;
  }

  @media (pointer: coarse) {
    display: none;
  }
}
