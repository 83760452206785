/**
  Copy of Boostrap 5.3.3
  --------------------------
  Removed:
  - Vertical button groups
  - Sizing
  - Split button dropdowns
  - .dropdown-toggle
**/

// Make the div behave like a button
.btn-group {
  position: relative;
  display: inline-flex;
  vertical-align: middle; // match .btn alignment given font-size hack above

  > .btn {
    position: relative;
    flex: 1 1 auto;
  }

  // Bring the hover, focused, and "active" buttons to the front to overlay
  // the borders properly
  > .btn:hover,
  > .btn:focus,
  > .btn:active,
  > .btn.active {
    z-index: 1;
  }
}

// Optional: Group multiple button groups together for a toolbar
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .input-group {
    width: auto;
  }
}

.btn-group {
  @include border-radius($btn-border-radius);

  // Prevent double borders when buttons are next to each other
  > :not(.btn-check:first-child) + .btn,
  > .btn-group:not(:first-child) {
    margin-left: calc(#{$btn-border-width} * -1); // stylelint-disable-line function-disallowed-list
  }

  // Reset rounded corners
  > .btn:not(:last-child),
  > .btn-group:not(:last-child) > .btn {
    @include border-end-radius(0);
  }

  // The left radius should be 0 if the button is:
  // - the "third or more" child
  // - the second child and the previous element isn't `.btn-check` (making it the first child visually)
  // - part of a btn-group which isn't the first child
  > .btn:nth-child(n + 3),
  > :not(.btn-check) + .btn,
  > .btn-group:not(:first-child) > .btn {
    @include border-start-radius(0);
  }
}


// --------------------------------------------------


.btn-group > .btn.active:not(:hover) {
  z-index: 2;
}
