@import "~public/theme/scss/bootstrap/variables";
@import "~public/theme/scss/bootstrap/mixins";

:root {
  --safe-area: env(safe-area-inset-bottom);
  --has-safe-area: calc(min(1px, var(--safe-area) * 100)); // returns 0px or 1px
  --has-no-safe-area: calc(1px - var(--has-safe-area));
  --pr-leaderboard-height: 100px;
  --pr-sticky-topbar-height: 0px;

  @include media-breakpoint-up(md) {
    --pr-leaderboard-height: 90px;
  }
}

.layout-fixed-container {
  padding-bottom: calc(max(#{map-get($spacers, 2)}, var(--safe-area))); // Select the biggest value between 0.5rem and safe-area
  transition: padding-bottom 0.25s linear;

  :global(.browser-iphone-safari) & {
    padding-bottom: calc(max(var(--has-safe-area) * 44 + var(--safe-area), var(--has-no-safe-area) * 22));
  }

  :global(html.is-iframe) & {
    display: none !important;
  }

  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;

  @include media-breakpoint-down(md) {
    &.isSticky {
      position: sticky;
    }
  }
}

.leaderboard {
  // Non-native ad
  // use height and overflow to avoid jumps at all cost
  &:not(.isNative) {
    height: var(--pr-leaderboard-height);
    overflow: hidden;
  }

  // Native ad
  &.isNative {
    min-height: var(--pr-leaderboard-height); // Use min-height in case native ad has long content
    position: relative;

    // Limit the width of the creative
    > *:first-child {
      width: 100%;
      z-index: 1;

      @include media-breakpoint-up(lg) {
        width: 728px;
      }

      @include media-breakpoint-up(xl) {
        width: 970px;
      }
    }

    // When the ad is supposed to be native but isn't
    &:has(iframe) {
      text-align: center;
    }
  }
}
