@import "~bootstrap/scss/forms/form-text";
@import "~bootstrap/scss/forms/form-select";
@import "~bootstrap/scss/forms/validation";

@import "./forms/form-control";
@import "./forms/form-check";
@import "./forms/input-group";

.form-control-xl {
  font-size: $font-size-lg;
  height: $input-height-lg;
  padding: $input-padding-y-lg $input-padding-x-lg;

  @include media-breakpoint-up(md) {
    font-size: $font-size-xl;
    height: $input-height-xl;
    padding: $input-padding-y-xl $input-padding-x-xl;
  }
}

// Static
.form-control.form-control-static {
  padding-right: 0;
  padding-left: 0;
  cursor: text;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

// Search
.form-control[type="search"],
.form-control.form-control-search {
  padding-left: ($input-padding-y * 2) + ($font-size-base * $input-line-height);
  padding-right: ($btn-padding-y * 2) + ($font-size-base * $input-line-height);
  background-image: escape-svg($form-icon-search);
  background-position: $input-padding-y center;
  background-repeat: no-repeat;
  background-size: $font-size-base * $input-line-height;

  &::-webkit-search-cancel-button {
    display: none;
  }

  &.form-control-lg {
    padding-left: ($input-padding-y-lg * 2) + ($font-size-lg * $input-line-height);
    padding-right: ($btn-padding-y-lg * 2) + ($font-size-lg * $input-line-height);
    background-position: $input-padding-y-lg center;
    background-size: ($font-size-lg * $input-line-height);
  }

  &.form-control-xl {
    @include media-breakpoint-up(md) {
      padding-left: ($input-padding-y-xl * 2) + ($font-size-xl * $input-line-height);
      padding-right: ($btn-padding-y-lg * 2) + ($font-size-lg * $input-line-height);
      background-position: $input-padding-y-xl center;
      background-size: ($font-size-xl * $input-line-height);

      @include media-breakpoint-up(md) {
        padding-right: ($btn-padding-y-xl * 2) + ($font-size-xl * $input-line-height);
      }
    }
  }
}

// Date
// Required for Mac Safari
.form-control[type="date"] {
  height: $input-height;

  &.form-control-xs {
    height: $input-height-xs;
  }
  &.form-control-sm {
    height: $input-height-sm;
  }
  &.form-control-lg {
    height: $input-height-lg;
  }
  &.form-control-xl {
    height: $input-height-lg;
    @include media-breakpoint-up(md) {
      height: $input-height-xl;
    }
  }

  &::-webkit-calendar-picker-indicator {
    margin: 0;
    padding: 0;
  }
}

// Select
.form-select {
  &:not([size]):not([multiple]) {
    padding-right: calc((#{$form-select-padding-x} * 2) + 0.5em);

    &.form-select-sm {
      padding-right: calc((#{$form-select-padding-x-sm} * 2) + 0.5em);
    }

    &.form-select-lg {
      padding-right: calc((#{$form-select-padding-x-lg} * 2) + 0.5em);
    }

    &.form-select-xl {
      padding-top: $form-select-padding-y-xl;
      padding-right: calc((#{$form-select-padding-x-xl} * 2) + 0.5em);
      padding-bottom: $form-select-padding-y-xl;
      padding-left: $form-select-padding-x-xl;
      @include font-size($form-select-font-size-xl);
    }
  }
}

// Checkbox/Radio inputs
// Increase the size of the clickable area
.form-check-input:not(:disabled) {
  position: relative;

  &::after {
    content: "";
    height: $form-check-padding-start;
    width: $form-check-padding-start;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
