@import "~shared/theme/scss/bootstrap/variables";
@import "~shared/theme/scss/bootstrap/mixins";
@import "~shared/theme/scss/bootstrap/modal";
@import "~shared/theme/scss/bootstrap/transitions";

.insetCloseButton {
  position: absolute;
  z-index: $zindex-modal;
  top: 0.75rem;
  right: 0.75rem;
  background: #fff;
  border-radius: 50%;

  &:hover, &:focus {
    background: #fff !important;
  }
}

.modal {
  .modal-helper {
    margin-top: calc((#{$font-size-base} * #{$line-height-base} + #{map-get($spacers, 4)} * 2) * -1);
    opacity: 0; /* opacity will be set to 1 in JS */
    text-shadow: 0 1px 2px $gray-500-rgba;
    transition: $transition-fade;
  }

  &:not(.show) .modal-helper {
    display: none !important;
  }

  .modal-content {
    transition: opacity 0.25s ease-out;
  }

  &.show {
    > :global(.readyToClose) {
      .modal-pullToClose {
        display: none !important;
      }

      .modal-content {
        opacity: 0.8;
      }
    }

    > :global(:not(.readyToClose)) {
      .modal-releaseToClose {
        display: none !important;
      }

      .modal-content {
        opacity: 1;
      }
    }
  }
}
