@import "~public/theme/scss/bootstrap/variables";
@import "~public/theme/scss/bootstrap/mixins";

.fixed-advertisement-container {
  background-color: $card-bg;
  border-radius: subtract($card-border-radius, $card-border-width);
  box-shadow: 0 0 0 $card-border-width $card-border-color, $box-shadow-lg;
  margin: $card-border-width;
  min-height: add($sticky-bottom-ad-mobile-height, $sticky-bottom-ad-container-padding * 2);

  &:not(:has(iframe)) > *:first-child {
    margin-inline: map-get($spacers, 2);
  }

  @include media-breakpoint-up(md) {
    @media (min-height: $mobile-landscape-height) {
      &:not(.is-native) {
        min-height: add($sticky-bottom-ad-tablet-height, $sticky-bottom-ad-container-padding * 2);
      }
    }
  }

  @media (max-height: $mobile-landscape-height) {
    display: none !important;
  }
}
