@import "~public/theme/scss/bootstrap/variables";
@import "~public/theme/scss/bootstrap/mixins";

.result {
  // disable the hover style on touch devices (problematic on iOS)
  @media (pointer: coarse) {
    &:not(:active):not(.active):hover,
    &:not(:active):not(.active).hover {
      background-color: transparent;
      border-left-color: transparent;
      color: inherit;
    }
  }
}
