@import "~public/theme/scss/bootstrap/variables";
@import "~public/theme/scss/bootstrap/mixins";

.invisibleAnchor.invisibleAnchor {
  visibility: hidden;
  width: 0;
  height: 0;
  position: relative !important;
  margin: 0 !important;
  padding: 0 !important;
  top: calc(var(--pr-sticky-topbar-height) * -1);
}
