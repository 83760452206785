@import "~shared/theme/scss/bootstrap/variables";
@import "~shared/theme/scss/bootstrap/mixins";

.slider {
  scroll-snap-type: x mandatory;

  > * {
    scroll-snap-align: start;
  }

  // Hides scrollbar
  -ms-overflow-style: none; // IE

  @media (pointer: fine) {
    scrollbar-width: none;  // Firefox

    &::-webkit-scrollbar {  // Webkit
      display: none;
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    // Slide's width based on number of slides visible
    @for $i from 1 through 6 {
      .slide-without-preview#{$infix}-#{$i} {
        $slide-width: percentage(1 / $i);

        flex-basis: $slide-width;
        max-width: $slide-width;
      }
      .slide-with-preview#{$infix}-#{$i} {
        $slide-width: percentage(1 / ($i + 0.25)); // add 0.25 so 25% of the next slide is visible

        flex-basis: $slide-width;
        max-width: $slide-width;
      }
    }
  }
}
