@import "~public/theme/scss/bootstrap/variables";
@import "~public/theme/scss/bootstrap/mixins";

.footer {
  &_list {
    list-style: square inside;
  }

  &_disclaimer {
    background-color: $gray-50-rgba;
    border-top: $border-width solid $gray-100-rgba;
  }
}
