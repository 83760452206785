@import '~public/theme/scss/bootstrap/_variables';
@import '~public/theme/scss/bootstrap/_mixins';

.slideOut {
  position: fixed;
  top: 0;
  bottom: 0;
  transition: transform 0.3s ease-out;
  will-change: transform;
  z-index: $zindex-modal;
  pointer-events: none;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  width: 80%;
  max-width: 30rem;

  &.end {
    right: 0;
    transform: translateX(100%);
  }

  &.start {
    left: 0;
    transform: translateX(-100%);
  }

  &.entering,
  &.entered {
    transform: translateX(0);
    outline: $border-width solid $gray-200-rgba;
  }

  &.entered {
    overflow-y: auto;
    pointer-events: auto;
    transition: transform 130ms ease-in;
  }
}

.slideOut-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.3s ease-out;
  will-change: opacity;
  opacity: 0;
  z-index: $zindex-modal-backdrop;
  background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);

  &.entering,
  &.entered {
    opacity: 1;
  }
}

.slideOut-content {
  min-height: 100%;
  background-color: $dropdown-bg;
}
