@import "~bootstrap/scss/mixins/grid";

@mixin make-cssgrid-columns($breakpoints: $grid-breakpoints, $columns: $grid-columns) {
  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      @for $i from 1 through $columns {
        &.columns#{$infix}-#{$i} {
          --#{$prefix}columns: #{$i};
        }
      }
    }
  }
}
