// Configuration
@import "functions";
@import "variables";
@import "maps";
@import "mixins";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/containers";
@import "reboot";

// Card
@import "~shared/theme/scss/bootstrap/card";

// Forms
@import "forms";
@import "custom-forms";

// Button
@import "~shared/theme/scss/bootstrap/buttons";
@import "~shared/theme/scss/bootstrap/button-group";

// Dropdown
@import "~shared/theme/scss/bootstrap/dropdown";

// Table
@import "~shared/theme/scss/bootstrap/tables";

// Badge
@import "~shared/theme/scss/bootstrap/badge";

// Popover
@import "~shared/theme/scss/bootstrap/popover";

// Alert
@import "~shared/theme/scss/bootstrap/alert";

// Container query
.enable-container-query {
  container-type: inline-size;
}
