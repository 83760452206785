@import "~bootstrap/scss/reboot";

body {
  background-color: var(--#{$prefix}secondary-bg);
  -webkit-tap-highlight-color: rgba(0, 166, 255, 0.428) !important;
}

svg {
  fill: currentColor;
  stroke: currentColor;
  stroke-width: 0;
  vertical-align: top;
}

textarea {
  overflow-x: hidden;
  resize: none;
}

button {
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

a {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
  }
}

legend {
  color: $headings-color;
  font-size: $h4-font-size;
  font-weight: $headings-font-weight;
}

[tabindex] {
  &:focus {
    outline: none;
  }
}

hr {
  height: $hr-height;
  background-color: $hr-bg-color;
}
