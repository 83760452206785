@import "~bootstrap/scss/card";

@mixin fullwidth-card {
  --#{$prefix}card-border-radius: none;
  --#{$prefix}card-cap-padding-x: #{$grid-gutter-width / 2};
  --#{$prefix}card-inner-border-radius: none;
  --#{$prefix}card-spacer-x: #{$grid-gutter-width / 2};

  border-inline-width: 0px;
  margin-inline: calc(var(--#{$prefix}gutter-x) / -2) !important;
}

@include media-breakpoint-only(xs) {
  .card-full {
    @include fullwidth-card;
  }
}

@include media-breakpoint-only(sm) {
  .card-full-sm {
    @include fullwidth-card;
  }
}

@include media-breakpoint-only(md) {
  .card-full-md {
    @include fullwidth-card;
  }
}

.card-img {
  height: 100%;
}
