@import "~bootstrap/scss/images";

img[alt] {
  color: $text-muted;
  display: inline-block;
  font-size: $font-size-sm;
  font-weight: $font-weight-base;
  overflow: hidden;
}

.img-cover {
  object-fit: cover;
}

.img-contain {
  object-fit: contain;
}

.img-thumbnail {
  padding: $thumbnail-padding;
  background-color: $thumbnail-bg;
  border: $thumbnail-border-width solid $thumbnail-border-color;
  @include border-radius($thumbnail-border-radius);
  @include box-shadow($thumbnail-box-shadow);
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: ($spacer / 2);
  line-height: 1;
}

.figure-caption {
  font-size: $figure-caption-font-size;
  color: $figure-caption-color;
}
