@import "~public/theme/scss/bootstrap/variables";
@import "~public/theme/scss/bootstrap/mixins";

.sticky-sidebar-ad {
  @include media-breakpoint-up(lg) {
    // The top spacing should be half of the vertical space available but zero if the ad is taller than the viewport.
    top: calc(max((100vh - var(--sticky-sidebar-ad-height, 600px)) / 2, 0px));

    // We can disable alignment for some ads if needed
    // &:has([data-disable-vertical-alignment]) {--sticky-sidebar-ad-height: 100vh !important; }
    &:has(iframe[height^="600"]) {--sticky-sidebar-ad-height: 600px; }
    &:has(iframe[height^="250"]) {--sticky-sidebar-ad-height: 250px; }
  }

  @include media-breakpoint-up(xxl) {
    top: var(--pr-sticky-sidebar-top, #{map-get($spacers, 3)});
  }
}
