@import "~shared/theme/scss/bootstrap/variables";
@import "~shared/theme/scss/bootstrap/mixins";


@mixin circle($fill) {
  background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' %3E%3Ccircle cx='24' cy='24' r='18' fill='#{$fill}'/%3E%3C/svg%3E"), "#", "%23");
}
@mixin star($fill) {
  background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' %3E%3Cpolygon points='48 18 33 29 39 48 24 36 9 48 15 29 0 18 18 18 24 0 30 18' fill='#{$fill}'/%3E%3C/svg%3E"), "#", "%23");
}


.rating {
  position: relative;

  &::after,
  &::before {
    content: '';
    background-position: left center;
    background-repeat: repeat-x;
    background-size: var(--pr-rating-size);
    display: block;
    flex-shrink: 0;
    height: var(--pr-rating-size);
  }

  &::after {
    margin-inline-end: calc((5 - var(--pr-rating-score)) * var(--pr-rating-size)); // required in case flex-direction: row-reverse is used
    position: absolute;
    width: calc(var(--pr-rating-score) * var(--pr-rating-size))
  }

  &::before {
    width: calc(var(--pr-rating-size) * 5);
  }
}


// Types
.rating--circles {
  &::after {
    @include circle('#98b872');
  }

  &::before {
    @include circle($gray-200-rgba);
  }
}

.rating--stars {
  &::after {
    @include star($yellow);
  }

  &::before {
    @include star($gray-200-rgba);
  }
}


// Sizes
.rating--xxsmall {
  --pr-rating-size: 14px;
}

.rating--xsmall {
  --pr-rating-size: 16px;
}

.rating--small {
  --pr-rating-size: 18px;
}

.rating--medium {
  --pr-rating-size: 21px;
}

.rating--large {
  --pr-rating-size: 24px;
}


// 0/5
.rating--0 {
  --pr-rating-score: 0;

  &::after {
    content: unset;
  }
}

// 5/5
.rating--50 {
  &::after {
    order: -1;
    position: unset;
  }

  &::before {
    content: unset;
  }
}
