.form-check-input {
  box-shadow: $form-check-input-box-shadow;
}


// Switches
.form-switch {
  .form-check-input {
    height: $form-switch-height;
    margin-top: ($line-height-base - $form-switch-height) / 2;
    background-color: $form-switch-bg-color;

    &:checked {
      background-color: $form-switch-checked-bg-color;
    }
  }
}

.form-switch--end {
  padding-left: unset;
  padding-right: $form-switch-padding-start;

  .form-check-input {
    float: right;
    margin-left: unset;
    margin-right: $form-switch-padding-start * -1;
  }
}


// Input type=[file]
.input-file {
  position: relative;
  cursor: pointer;

  input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  .btn {
    position: relative;
    z-index: 1;
  }

  &:hover .btn,
  input:focus + .btn,
  input:active + .btn {
    background-color: darken($btn-secondary-bg, 2%);
    border-color: darken($btn-secondary-border, 2%);
  }
}
