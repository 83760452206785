/*
  @import the scss modules from ./scss folder or from node_modules here
  to be included globally in the application
*/

// Bootstrap
@import "~public/theme/scss/bootstrap/bootstrap";

// Other
@import "~shared/theme/scss/unsupported-browser-message";

// Remove height caused by an empty space
div[id^='google_ads_iframe'] {
  line-height: 0;
}

html.is-iframe [data-hide-in-iframe] {
  display: none !important;
}
