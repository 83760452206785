/**
  Copy of Boostrap 5.3.3
  --------------------------
  Removed:
  - Wrapper: .dropup, .dropdend, .dropstart, .dropup-center, .dropdown-center
  - Move CSS variables outside .dropdown-menu
  - Dropdown text
  - Dark dropdowns
**/

.dropdown {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

// The dropdown menu
.dropdown-menu {
  // scss-docs-start dropdown-css-vars
  --#{$prefix}dropdown-zindex: #{$zindex-dropdown};
  --#{$prefix}dropdown-min-width: #{$dropdown-min-width};
  --#{$prefix}dropdown-padding-x: #{$dropdown-padding-x};
  --#{$prefix}dropdown-padding-y: #{$dropdown-padding-y};
  --#{$prefix}dropdown-spacer: #{$dropdown-spacer};
  @include rfs($dropdown-font-size, --#{$prefix}dropdown-font-size);
  --#{$prefix}dropdown-color: #{$dropdown-color};
  --#{$prefix}dropdown-bg: #{$dropdown-bg};
  --#{$prefix}dropdown-border-color: #{$dropdown-border-color};
  --#{$prefix}dropdown-border-radius: #{$dropdown-border-radius};
  --#{$prefix}dropdown-border-width: #{$dropdown-border-width};
  --#{$prefix}dropdown-inner-border-radius: #{$dropdown-inner-border-radius};
  --#{$prefix}dropdown-box-shadow: #{$dropdown-box-shadow};
  // scss-docs-end dropdown-css-vars

  position: absolute;
  z-index: var(--#{$prefix}dropdown-zindex);
  min-width: var(--#{$prefix}dropdown-min-width);
  padding: var(--#{$prefix}dropdown-padding-y) var(--#{$prefix}dropdown-padding-x);
  margin: 0; // Override default margin of ul
  @include font-size(var(--#{$prefix}dropdown-font-size));
  color: var(--#{$prefix}dropdown-color);
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  list-style: none;
  background-color: var(--#{$prefix}dropdown-bg);
  background-clip: padding-box;
  border: var(--#{$prefix}dropdown-border-width) solid var(--#{$prefix}dropdown-border-color);
  @include border-radius(var(--#{$prefix}dropdown-border-radius));
  @include box-shadow(var(--#{$prefix}dropdown-box-shadow));

  @if $dropdown-padding-y == 0 {
    > .dropdown-item:first-child,
    > li:first-child .dropdown-item {
      @include border-top-radius(var(--#{$prefix}dropdown-inner-border-radius));
    }
    > .dropdown-item:last-child,
    > li:last-child .dropdown-item {
      @include border-bottom-radius(var(--#{$prefix}dropdown-inner-border-radius));
    }

  }
}


// Dividers (basically an `<hr>`) within the dropdown
.dropdown-divider {
  height: 0;
  margin: var(--#{$prefix}dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--#{$prefix}dropdown-divider-bg);
  opacity: 1; // Revisit in v6 to de-dupe styles that conflict with <hr> element
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
  display: block;
  width: 100%; // For `<button>`s
  padding: var(--#{$prefix}dropdown-item-padding-y) var(--#{$prefix}dropdown-item-padding-x);
  clear: both;
  font-weight: $font-weight-normal;
  color: var(--#{$prefix}dropdown-link-color);
  text-align: inherit; // For `<button>`s
  text-decoration: if($link-decoration == none, null, none);
  background-color: transparent; // For `<button>`s
  border: 0; // For `<button>`s
  @include border-radius(var(--#{$prefix}dropdown-item-border-radius, 0));

  &:hover,
  &:focus {
    color: var(--#{$prefix}dropdown-link-hover-color);
    text-decoration: if($link-hover-decoration == underline, none, null);
    @include gradient-bg(var(--#{$prefix}dropdown-link-hover-bg));
  }

  &.active,
  &:active {
    color: var(--#{$prefix}dropdown-link-active-color);
    text-decoration: none;
    @include gradient-bg(var(--#{$prefix}dropdown-link-active-bg));
  }

  &.disabled,
  &:disabled {
    color: var(--#{$prefix}dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent;
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
  }
}

// Dropdown section headers
.dropdown-header {
  display: block;
  padding: var(--#{$prefix}dropdown-header-padding-y) var(--#{$prefix}dropdown-header-padding-x);
  margin-bottom: 0; // for use with heading elements
  color: var(--#{$prefix}dropdown-header-color);
  white-space: nowrap; // as with > li > a
}


// ------------------------------------


// CSS Variables - Start
.dropdown-divider {
  --#{$prefix}dropdown-divider-bg: #{$dropdown-divider-bg};
  --#{$prefix}dropdown-divider-margin-y: #{$dropdown-divider-margin-y};
}

.dropdown-item {
  --#{$prefix}dropdown-link-color: #{$dropdown-link-color};
  --#{$prefix}dropdown-link-hover-color: #{$dropdown-link-hover-color};
  --#{$prefix}dropdown-link-hover-bg: #{$dropdown-link-hover-bg};
  --#{$prefix}dropdown-link-active-color: #{$dropdown-link-active-color};
  --#{$prefix}dropdown-link-active-bg: #{$dropdown-link-active-bg};
  --#{$prefix}dropdown-link-disabled-color: #{$dropdown-link-disabled-color};
  --#{$prefix}dropdown-item-padding-x: #{$dropdown-item-padding-x};
  --#{$prefix}dropdown-item-padding-y: #{$dropdown-item-padding-y};
}

.dropdown-header {
  --#{$prefix}dropdown-header-color: #{$dropdown-header-color};
  --#{$prefix}dropdown-header-padding-x: #{$dropdown-header-padding-x};
  --#{$prefix}dropdown-header-padding-y: #{$dropdown-header-padding-y};
}
// CSS Variables - End


.dropdown-toggle--down {
  @include caret(down);
}

.dropdown-toggle--up {
  @include caret(up);
}

.dropdown-item {
  white-space: normal;

  &:not(.disabled) {
    cursor: pointer;
  }

  // Simulate :hover style when navigating dropdown items with keyboard
  &.hover {
    @extend :hover;
  }

  &:active {
    // override children's color on non-touch devices
    @media (hover: hover) {
      color: $dropdown-link-active-color !important;

      * {
        color: $dropdown-link-active-color !important;
      }
    }

    // disable style on touch devices (problematic when scrolling)
    @media (hover: none) {
      background-color: inherit;
      color: inherit;
    }
  }
}

.dropdown-select.active {
  border-color: $form-select-focus-border-color;
  outline: 0;
  @include box-shadow($form-select-box-shadow, $form-select-focus-box-shadow);
}
