@import "~shared/theme/scss/bootstrap/variables";
@import "~shared/theme/scss/bootstrap/mixins";

.background,
.spinner {
  border-style: solid;
}

.background {
  border-color: $gray-200-rgba;
}

.spinner {
  animation: loader 0.5s linear;
  animation-iteration-count: infinite;
  border-color: transparent;
  border-bottom-color: inherit;
}

// Animation
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

// Generate sizes
$sizes: (
  small:  1em,
  medium: 1.5em,
  large:  2em
);

@each $size, $length in $sizes {
  .loader-#{$size} {
    height: $length;
    width: $length;

    .background,
    .spinner {
      border-width: ($length / 8);
    }
  }
}
