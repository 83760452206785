@import "~public/theme/scss/bootstrap/variables";
@import "~public/theme/scss/bootstrap/mixins";

.navbar {
  background-color: var(--bs-primary);
  box-shadow: inset 0 ($border-width * -1) 0 $gray-200-rgba, 0 $border-width 0 $gray-100-rgba;

  @include media-breakpoint-down(lg) {
    &.light {
      background-color: var(--bs-white);
      box-shadow: 0 $border-width 0 $gray-100-rgba;
    }
  }
}

.navbar-logo {
  max-width: 220px;
  width: 100%;
}

.navbar > nav:has(.navbar-category-link) {
  @include media-breakpoint-up(lg) {
    background-image: linear-gradient(to right, transparent 13px, $white 13px, $white 50px, #e6ffc8 100px, transparent 50%);
  }
}

.navbar-username {
  @include media-breakpoint-up(lg) {
    max-width: 12ch;
  }
}

.navbar-search-input.navbar-search-input {
  border-color: $gray-200-rgba;
  padding-right: $input-padding-x;
  width: auto;

  @include media-breakpoint-up(lg) { width: 16rem }
  @include media-breakpoint-up(xl) { width: 25rem }
  &.short { width: 16rem }
}

.navbar-search-magnifier {
  max-width: 8rem;
}

.navbar-button {
  @include button-variant(
    shade-color($primary, 10%),
    shade-color($primary, 10%),
  );
}

.navbar-button + .navbar-button {
  @include button-variant(
    shade-color($primary, 20%),
    shade-color($primary, 20%),
  );
}
