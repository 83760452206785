@import "~bootstrap/scss/modal";


// Makes sure the scrolling inside the modal doesn't conflict with the
// window's scrolling for all browsers expect Safari < 16
.modal-body {
  overscroll-behavior: none;
}

:global(.browser-iphone-safari) {
  .modal-content {
    margin-bottom: env(safe-area-inset-bottom);
  }
}


// In test
// This is to prevent Puppeteer from taking screenshots with the box-shadow,
// which messes up the diffs because the background is semi-transparent and
// what's behind it is unpredictable
:global(.in-test) {
  .modal-dialog {
    padding: 0 !important;
  }

  .modal-content {
    border-radius: 0;
    border: none;
    box-shadow: none;
  }

  .modal-backdrop {
    opacity: 0;
  }
}
