@import "~bootstrap/scss/tables";

.table {
  > :not(caption) {
    // Remove the bottom border of the cells of the last row of the last child of the table
    &:last-child > *:last-child > * {
      border-bottom-width: 0;
    }
  }

  > :not(:first-child) {
    border-top-width: $table-border-width;
  }

  > colgroup + * {
    border-top-width: 0 !important;
  }
}


//
// Border versions
//

.table-bordered > :not(caption) {
  // Remove the top border of the first row of the first child of the table
  &:first-child > *:first-child {
    border-top-width: 0;
  }

  // Remove the bottom border of the last row of the last child of the table
  &:last-child > *:last-child {
    border-bottom-width: 0;
  }

  > * > * {
    // Remove the left border of the first cell of a row
    &:first-child {
      border-left-width: 0;
    }
      
    // Remove the right border of the last cell of a row
    &:last-child {
      border-right-width: 0;
    }
  }
}


//
// Used on the admin to simulate odd/even rows
//

.table-striped--double {
  tbody tr:nth-child(4n + 1),
  tbody tr:nth-child(4n + 2) {
    background-color: $table-striped-bg;
  }

  tbody tr:nth-child(4n + 1),
  tbody tr:nth-child(4n + 3) {
    > * {
      padding-bottom: 0;
      border-bottom-width: 0;
    }
  }
}
