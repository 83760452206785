@import "./bootstrap-buttons";

//
// Alternate buttons
//

@each $color, $value in $button-colors {
  .btn-#{$color} {
    @if $color == 'primary-light' {
      // Overrides the text color for all states by using $primary-dark
      @include button-variant(
        $value,
        shade-color($value, $btn-border-shade-amount),
        $color: $primary-dark,
        $hover-color: $primary-dark,
        $active-color: $primary-dark,
        $disabled-color: $primary-dark
      );
    }
    @else if $color == 'primary-dark' {
      // Overrides the background-color behaviour using tint
      @include button-variant(
        $value,
        shade-color($value, $btn-border-shade-amount),
        $hover-background: tint-color($value, $btn-hover-bg-tint-amount),
        $hover-border: tint-color($value, $btn-hover-border-tint-amount),
        $active-background: tint-color($value, $btn-active-bg-tint-amount),
        $active-border: tint-color($value, $btn-active-border-tint-amount)
      );
    }
    @else if $color == 'secondary' {
      // Overrides the text color for all states by using $btn-secondary-color
      @include button-variant(
        $value,
        shade-color($value, $btn-border-shade-amount),
        $color: $btn-secondary-color,
        $hover-background: shade-color($value, $btn-hover-bg-shade-amount),
        $hover-border: shade-color($value, $btn-hover-border-shade-amount),
        $hover-color: $btn-secondary-color,
        $active-background: shade-color($value, $btn-active-bg-shade-amount),
        $active-border: shade-color($value, $btn-active-border-shade-amount),
        $active-color: $btn-secondary-color,
        $disabled-color: $btn-secondary-color,
      );
    }
    @else if $color == 'light' {
      // Overrides the text color for all states by using $body-color
      @include button-variant(
        $value,
        shade-color($value, 20%),
        $color: $body-color,
        $hover-background: shade-color($value, $btn-hover-bg-shade-amount),
        $hover-border: shade-color($value, $btn-hover-border-shade-amount),
        $hover-color: $body-color,
        $active-background: shade-color($value, $btn-active-bg-shade-amount),
        $active-border: shade-color($value, $btn-active-border-shade-amount),
        $active-color: $body-color,
        $disabled-color: $body-color,
      );
    }
    @else if $color == 'dark' {
      // Overrides the background opacity for all states
      @include button-variant(
        $value,
        $value,
        $hover-background: rgba($value, 0.8),
        $active-background: rgba($value, 0.9),
      );
    }
    @else {
      @include button-variant(
        $value,
        shade-color($value, $btn-border-shade-amount)
      );
    }
  }
}

.btn-light {
  font-weight: $font-weight-normal;
}


//
// Link buttons
//

// Make a button look and behave like a link
.btn.btn-link {
  border-width: 0;
  padding-block: calc(var(--bs-btn-padding-y) + var(--bs-btn-border-width));

  &:not(.btn-icon) {
    padding-inline: 0;
  }
}


//
// Button Sizes
//

.btn-xs {
  @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $font-size-xs, $btn-border-radius);
}

.btn-xl {
  @extend .btn-lg;

  @include media-breakpoint-up(md) {
    @include button-size($btn-padding-y-xl, $btn-padding-x-xl, $btn-font-size-xl, $btn-border-radius);
  }
}


//
// Icons
//

.btn > svg,
.btn.disabled > span > svg {
  height: 1.5em;
  width: 1.5em;
}

.btn-icon-left > svg,
.btn-icon-left.disabled > span > svg {
  margin-right: var(--#{$prefix}btn-padding-y);
}

.btn-icon-right > svg,
.btn-icon-right.disabled > span > svg {
  margin-left: var(--#{$prefix}btn-padding-y);
}

.btn-icon {
  padding-left: var(--#{$prefix}btn-padding-y);
  padding-right: var(--#{$prefix}btn-padding-y);
}

.btn-icon-left:not(.btn-link) {
  padding-left: var(--#{$prefix}btn-padding-y);
}

.btn-icon-right:not(.btn-link) {
  padding-right: var(--#{$prefix}btn-padding-y);
}


//
// Block
//

.btn-block {
  display: block;
  width: 100%;
}
