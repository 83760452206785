@import "~public/theme/scss/bootstrap/variables";
@import "~public/theme/scss/bootstrap/mixins";

$desktop-screen-width: 1920px;

.quoteCTA {
  @include media-breakpoint-up(sm) {
    width: ($desktop-screen-width - map-get($container-max-widths, xl)) / 2;
  }
}

.quoteCTA-card {
  border-radius: $card-inner-border-radius;
}

.quoteCTA-body {
  background-color: rgba($primary-dark, .9);
  box-shadow: inset 0 0 0 $card-border-width $card-border-color;
  @include transition($transition-base);

  &:hover {
    background-color: rgba($primary-dark, .95);
  }
}

.quoteCTA-button {
  border-color: transparent !important;
  box-shadow: 0 0 0 $btn-border-width $gray-200-rgba !important;
}
