@import "~shared/theme/scss/bootstrap/variables";
@import "~shared/theme/scss/bootstrap/mixins";

.stack.stack > * {
  margin: unset;
}

@each $size, $spacer in $spacers {
  .stack-#{$size} {
    --pr-stack-spacer: #{$spacer / 2};
  }
}
