@import "~shared/theme/scss/bootstrap/variables";
@import "~shared/theme/scss/bootstrap/mixins";

.labelWithCount {
  --pr-label-count-color: #{$text-muted};
  --pr-label-count-font-size: #{$font-size-sm};

  &::after {
    color: var(--pr-label-count-color);
    font-size: var(--pr-label-count-font-size);
    font-weight: normal;
    font-variant-numeric: tabular-nums;
    text-wrap: nowrap;

    // This unicode char is a whitespace
    content: "\00a0(" attr(data-count) ")";
  }
}
