@import "~shared/theme/scss/bootstrap/variables";
@import "~shared/theme/scss/bootstrap/mixins";

#unsupported-browser-message {
  background: #ffffba;
  font-size: 1.5rem;
  color: #333;
  padding: 20px;

  a {
    color: $link-color;

    &:hover, &:focus {
      color: $link-hover-color;
      text-decoration: underline;
    }
  }
}
